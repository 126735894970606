<template>
  <div>
    <v-alert v-if="item.id == null && loaded" type="error">
      {{ $t("notFound") }}
    </v-alert>
    <div v-else>
      <hardware-shop-badge
        @openCart="$router.push({ name: 'hardware-cart' })"
      />
      <v-card light>
        <v-progress-linear :active="loading" indeterminate />
        <v-card-title>
          {{ item.name }}
        </v-card-title>
        <v-card-text>
          <v-container fill-height fluid>
            <v-layout class="align-center justify-center">
              <v-img
                v-if="item.image != null"
                :src="item.image.replace('gfo-dev', 'gfo')"
                :alt="item.name"
                max-width="540"
              />
            </v-layout>
          </v-container>
          <v-row>
            <v-col cols="12">
              <div v-html="item.description"></div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$router.go(-1)" :disabled="loading" text>
            {{ $t("back") }}
          </v-btn>
          <v-btn text @click="$router.push({ name: 'hardware-list' })">
            {{ $t("hardware list") }}
          </v-btn>

          <v-btn
            color="primary"
            @click="minusCart(item)"
            v-if="item.id in cart"
            :disabled="minMinus(item)"
            text
          >
            {{ $t("remove") }}
          </v-btn>
          <v-badge
            :content="cart[item.id] || 0"
            :value="cart[item.id] || 0"
            color="primary"
            overlap
          >
            <v-btn
              color="primary"
              @click="plusCart(item)"
              :disabled="maxPlus(item)"
              text
            >
              {{ $t("add") }}
            </v-btn>
          </v-badge>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import HardwareShopBadge from "@/components/orders/hardware/HardwareShopBadge";
import HardwareShopItemPrice from "./HardwareShopItemPrice";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("hardwareShop");

export default {
  name: "HardwareShopDetails",
  components: {
    HardwareShopItemPrice,
    HardwareShopBadge,
  },
  props: {
    itemId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
  }),
  watch: {
    loaded: {
      immediate: true,
      handler() {
        this.getHardware();
      },
    },
    "$store.state.session": function () {
      this.reset();
    },
  },
  computed: {
    ...mapState(["cart", "items", "loaded", "reference"]),
    ...mapGetters(["itemsInCart", "maxPlus", "minMinus"]),
    item() {
      this.itemId;
      this.items;
      if (this.loaded)
        for (var i = 0; i < this.items.length; i++) {
          var item = this.items[i];
          if (item.id == this.itemId) return item;
        }
      return {};
    },
  },
  methods: {
    ...mapMutations(["plusCart", "minusCart", "setCart", "removeCart"]),
    ...mapActions(["reset", "load"]),
    getHardware: function () {
      if (!this.loading && !this.loaded) {
        var that = this;
        this.loading = true;
        this.$http
          .get("shop/hardware", {
            params: {
              graduated_prices: true,
              reference: this.reference || null,
            },
          })
          .then((response) => {
            that.load(response.data);
          })
          .catch((err) => {
            if (err.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "got unexpected reponse": "got unexpected reponse",
    "response error": "response error",
    "hardware list": "Hardware list",
    "notFound": "item not found",
    "back": "Back",
    "add": "Add",
    "remove": "Remove"
  }, 
  "de": {
    "got unexpected reponse": "got unexpected reponse",
    "response error": "response error",
    "hardware list": "Hardware Liste",
    "notFound": "Artikel nicht gefunden",
    "back": "Zurück",
    "add": "Hinzufügen",
    "remove": "Entfernen"
  }
}
</i18n>

<style scoped>
.v-application .mt-15 {
  margin-top: 60px !important;
}
</style>
